body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: #F3F3F3;
}
     
*{
    margin: 0;
    padding: 0;
    outline: none !important;
}

/* *::-webkit-scrollbar{
  width: 0;
} */

.b{
      border: 2px solid red;
}
.form-select-root{
  border: none;
  border-radius: 5px;
  background-color: #FFF;
}
.form-select{
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    border: none;
    padding: 10px 15px;
    width: 120px;
}

.time-pecker{
  background-color: #FFF;
  border-radius: 5px;
  height: 60px;
  width: 200px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  padding-top: 20px;
}

.select_date{
  color: rgb(0, 102, 255);
  text-decoration: underline;
  cursor: pointer;
  margin: 30px;
}